import React from 'react'

import './styles.scss'

function Footer() {
  return (
    <div className="footer">
      <p className="footer__copyright">
        &#9400;&nbsp;{new Date().getFullYear()}&nbsp;COUTURE HUNTING
      </p>
    </div>
  )
}

export default Footer
