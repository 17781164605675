import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/SEO'

function EventsPage() {
  return (
    <Layout>
      <SEO title="Events" />

      <div>EVENTS CONTENT</div>
    </Layout>
  )
}

export default EventsPage
