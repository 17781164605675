import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Footer from '../Footer'

const Layout = ({ className, children }) => {
  return (
    <div className={classnames('layout', className)}>
      <div className="layout-content-wrapper">
        <main className="layout-content">{children}</main>

        <Footer />
      </div>
    </div>
  )
}

Layout.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
}
Layout.defaultProps = {
  className: '',
}

export default Layout
